import { ApiResultVoCommonAreaResponseVo } from '@schemas/non-auth'
import { createPrefetchQuery } from '@utils/createPrefetchQuery'
import { NewHomeReqModel } from '../types'
import { fetchAPI } from '@utils/http/apiRequest'

export type LiveResponse = CommonResponse<ApiResultVoCommonAreaResponseVo>
export type LiveResponseData = ApiResultVoCommonAreaResponseVo['data']

const path = '/restapi/v2/get/template/news-home/live'
const queryKeyString = ['@template/news-home/live'] as const

const getQuery = async (
	props: NewHomeReqModel,
	cookieHeader?: string,
): Promise<ApiResultVoCommonAreaResponseVo> => {
	const { areaIdx } = props
	const params = new URLSearchParams({
		...(areaIdx ? { areaIdx: String(areaIdx) } : {}),
	})
	return fetchAPI({ path, params, cookieHeader })
}

export const queryLive = (props: NewHomeReqModel, cookieHeader?: string) => ({
	queryKey: [...queryKeyString],
	queryFn: () => getQuery(props, cookieHeader),
})

export const prefetchLive = (props: NewHomeReqModel, cookieHeader?: string) =>
	createPrefetchQuery(queryKeyString, () => getQuery(props, cookieHeader))
