'use client'

import { Box } from '@mui/joy'
import ContentCard, { ContentCardProps } from '@components/content/ContentCard'
import { pxToRem } from '@ui/style/muiTheme'
import color from '@ui/style/color.theme'
import { getRelativeTime } from '@utils/date'
import getBulletColor from '@utils/getBulletColor'
import CSSgridList from '@components/list/CSSgridList'
import { CSSGridListBorder } from '@components/list/CSSgridList/CSSgridListRoot'
import { theme } from '@ui/style'
import { ArticleItemProps } from '..'
import { getArticleLink } from '@utils/getLink'
import { imgDefaultH } from 'src/shared/variables'

const ListMDimage2 = ({
	list = [],
	isDark,
	areaIdx,
}: {
	list: Array<ArticleItemProps>
	isDark?: boolean
	areaIdx?: number
}) => {
	const [article1st, ...articleList] = list

	const mainCardProps: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			flexDirection: { xs: 'column', md: 'row' },
			layoutGap: pxToRem(16),
			layoutSx: {
				'.set-bg &&&, .set-color &&&': {
					margin: { xs: `${pxToRem(-42)} ${pxToRem(-20)} 0`, md: 0 },
				},
				margin: { xs: `0 ${pxToRem(-20)} 0`, md: 0 },
				pb: { xs: pxToRem(12), md: 0 },
			},
		},
		thumbnailConfig: {
			badgeInset: pxToRem(8),
			thumbWidth: {
				xs: '100%',
				md: pxToRem(292),
				lg: pxToRem(305),
				xl: pxToRem(349),
			},
			thumbHeight: {
				md: pxToRem(164),
				lg: pxToRem(172),
				xl: pxToRem(196),
			},
			thumbSx: { '&.thumb': { marginRight: { lg: pxToRem(20) } } },
		},
		infoConfig: {
			isDark,
			bodyClamp: 2,
			titleClamp: 2,
			infoGap: pxToRem(12),
			infoSx: {
				paddingX: { xs: pxToRem(20), md: 0 },
				height: {
					md: pxToRem(164),
					lg: pxToRem(172),
					xl: pxToRem(196),
				},
				'.info-title': {
					fontSize: { xs: pxToRem(26), md: pxToRem(22), xl: pxToRem(26) },
					lineHeight: { xs: pxToRem(34), md: pxToRem(30), xl: pxToRem(34) },
					WebkitLineClamp: {
						md: 4,
						lg: 2,
					},
				},
				'.info-body': {
					display: {
						md: 'none',
						lg: '-webkit-box',
					},
					WebkitLineClamp: {
						lg: 3,
					},
					fontSize: {
						xs: pxToRem(14),
						xl: pxToRem(16),
					},
					lineHeight: {
						xs: pxToRem(20),
						xl: pxToRem(24),
					},
				},
				'.info-additionalBox': {
					marginTop: { xs: pxToRem(16), md: 'auto' },
				},
			},
		},
	}

	const subCardProps1: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			flexDirection: { xs: 'row-reverse', lg: 'row' },
			layoutGap: pxToRem(16),
			layoutSx: {
				position: 'relative',
				'&::after': {
					content: '""',
					width: '1px',
					height: pxToRem(70),
					background: isDark ? 'rgba(255,255,255,0.2)' : color.colGray3,
					position: 'absolute',
					bottom: '0',
					top: '0',
					right: { xs: pxToRem(-12), lg: pxToRem(-16) },
					display: { xs: 'none', md: 'block', lg: 'none' },
				},
			},
		},
		thumbnailConfig: {
			thumbWidth: { xs: pxToRem(120), xl: pxToRem(144) },
			thumbHeight: {
				xs: pxToRem(68),
				xl: pxToRem(82),
			},
			badgeInset: pxToRem(6),
			thumbSx: {
				display: {
					md: 'none',
					lg: 'block',
				},
				alignSelf: 'flex-start',
			},
		},
		infoConfig: {
			isDark,
			infoSx: {
				'.info-title': {
					fontSize: {
						xs: pxToRem(16),
						xl: pxToRem(17),
					},
					lineHeight: {
						xs: pxToRem(22),
						xl: pxToRem(24),
					},
					height: {
						md: pxToRem(44),
						xl: pxToRem(48),
					},
				},
			},
		},
	}

	const subCardProps2: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			flexDirection: { xs: 'row-reverse', lg: 'row' },
			layoutGap: pxToRem(16),
		},
		thumbnailConfig: {
			thumbWidth: { xs: pxToRem(120), xl: pxToRem(144) },
			thumbHeight: {
				xs: pxToRem(68),
				xl: pxToRem(82),
			},
			badgeInset: pxToRem(6),
			thumbSx: {
				display: {
					md: 'none',
					lg: 'block',
				},
				alignSelf: 'flex-start',
			},
		},
		infoConfig: {
			isDark,
			infoSx: {
				'.info-title': {
					fontSize: {
						xs: pxToRem(16),
						xl: pxToRem(17),
					},
					lineHeight: {
						xs: pxToRem(22),
						xl: pxToRem(24),
					},
					height: {
						md: pxToRem(44),
						xl: pxToRem(48),
					},
				},
			},
		},
	}

	const subCardProps3: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			flexDirection: { xs: 'row-reverse', lg: 'row' },
			layoutGap: pxToRem(16),
		},
		thumbnailConfig: {
			thumbWidth: { xs: pxToRem(120), xl: pxToRem(144) },
			thumbHeight: {
				xs: pxToRem(68),
				xl: pxToRem(82),
			},
			badgeInset: pxToRem(6),
			thumbSx: {
				display: {
					md: 'none',
					xl: 'block',
				},
			},
		},
		infoConfig: {
			isDark,
			infoSx: {
				'.info-title': {
					fontSize: {
						xs: pxToRem(16),
						xl: pxToRem(17),
					},
					lineHeight: {
						xs: pxToRem(22),
						xl: pxToRem(24),
					},
					height: {
						md: pxToRem(44),
						xl: pxToRem(48),
					},
				},
			},
		},
	}
	const subCardProps = [subCardProps1, subCardProps2, subCardProps3]

	const createCardContent = (item: ArticleItemProps) => ({
		articleIdx: item?.articleIdx,
		src:
			(item?.vodInfo ? item?.vodInfo.vodThumbnailImgUrl : item?.articleThumbnailImgUrl) ??
			undefined,
		href: getArticleLink(item, 'HOME', areaIdx),
		alt: '',
		title: item?.articleTitle,
		additionalText: getRelativeTime(item?.publicationDate ?? '', item?.code === 'LIVE'),
		duration: item?.vodInfo?.playTime,
		bullet: item?.bulletType && item.bulletType.name,
		bulletColor: item?.bulletType && getBulletColor(item.bulletType.name),
		showLiveBadge: item?.code === 'LIVE',
		liveTagTypeCode: item?.liveTagTypeCode,
		vodInfo: item?.vodInfo,
	})
	const drawArticleItem = (item: ArticleItemProps, index: number) =>
		index < 5 && (
			<Box
				key={`ListMDimage2-${item.code}-item-${item.articleIdx}`}
				gridArea={`sub${index + 1}`}
				className={`sub-item sub-item-${index + 1}`}
				{...(index === 0 && { borderRight: '0!important' })}
				sx={{
					'&.sub-item-1, &.sub-item-2': {
						'>*': {
							borderTop: {
								md: 0,
							},
							marginTop: {
								md: '1px',
							},
						},
					},
				}}
			>
				<ContentCard
					content={createCardContent(item)}
					layoutConfig={subCardProps[index > 2 ? 2 : index].layoutConfig}
					thumbnailConfig={subCardProps[index > 2 ? 2 : index].thumbnailConfig}
					infoConfig={subCardProps[index > 2 ? 2 : index].infoConfig}
				/>
			</Box>
		)
	return (
		<CSSgridList
			className="List-MDimage2"
			gridTemplateRows={{ md: 'repeat(3, 1fr)' }}
			gridTemplateColumns={{
				xs: '1fr',
				md: 'repeat(3, 1fr)',
				lg: `repeat(2, ${pxToRem(360)}) ${pxToRem(240)}`,
				xl: 'repeat(3, 1fr)',
			}}
			gridTemplateAreas={{
				xs: `
						"main"
						"sub1"
						"sub2"
						"sub3"
						"sub4"
						"sub5"
					`,
				md: `
						"main main sub3"
						"main main sub4"
						"sub1 sub2 sub5"
					`,
			}}
			overflow={{ xs: 'visible', md: 'hidden' }}
			isDark={isDark}
			sx={{
				[theme.breakpoints.down('md')]: {
					...CSSGridListBorder('horizontal', isDark),
					'.sub-item:nth-child(5)': {
						paddingBottom: 0,
						borderBottom: 0,
					},
					'.sub-item:nth-child(5) ~ .sub-item': {
						display: 'none',
					},
				},
				[theme.breakpoints.up('md')]: {
					...CSSGridListBorder('both', isDark),
				},
			}}
		>
			{/* main, sub1 에는 특정한 보더 스타일을 위해 임의의 스타일이 적용됨 */}
			<Box
				gridArea="main"
				// border custom
				sx={{
					position: 'relative',
					'&::after': {
						content: '""',
						display: { xs: 'none', md: 'block' },
						width: `calc(100% - ${pxToRem(16)} )`,
						height: '1px',
						background: isDark ? 'rgba(255,255,255,0.2)' : color.colGray3,
						position: 'absolute',
						bottom: '-1px',
						left: '0',
					},
				}}
			>
				<ContentCard
					content={{
						...createCardContent(article1st),
						body: article1st.articleInnerTextContent ?? '',
					}}
					layoutConfig={mainCardProps.layoutConfig}
					thumbnailConfig={mainCardProps.thumbnailConfig}
					infoConfig={mainCardProps.infoConfig}
				/>
			</Box>
			{/* border custom */}
			{articleList?.map(drawArticleItem)}
		</CSSgridList>
	)
}

export default ListMDimage2
