'use client'

import { Box } from '@mui/joy'
import ContentCard, { ContentCardProps } from '@components/content/ContentCard'
import { pxToRem } from '@ui/style/muiTheme'
import { getRelativeTime } from '@utils/date'
import getBulletColor from '@utils/getBulletColor'
import CSSgridList from '@components/list/CSSgridList'
import { theme } from '@ui/style'
import { CSSGridListBorder } from '@components/list/CSSgridList/CSSgridListRoot'
import { ArticleItemProps } from '..'
import { getArticleLink } from '@utils/getLink'
import { imgDefaultH } from 'src/shared/variables'

const ListMDimage1 = ({
	list = [],
	isDark,
	areaIdx,
}: {
	list: Array<ArticleItemProps>
	isDark?: boolean
	areaIdx?: number
}) => {
	const mainList = [...list].slice(0, 2)
	const subList = [...list].slice(2)

	const mainCardProps1: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			flexDirection: { xs: 'column', md: 'row' },
			layoutGap: { xs: pxToRem(16), md: pxToRem(20), xl: pxToRem(24) },
			layoutSx: {
				'.set-bg &&&, .set-color &&&': {
					marginTop: { xs: pxToRem(-42), md: 0 },
				},
				margin: { xs: `0 -${pxToRem(20)}`, md: 0 },
				pb: { xs: pxToRem(12), md: 0 },
			},
		},
		thumbnailConfig: {
			badgeInset: pxToRem(8),
			thumbWidth: {
				xs: '100%',
				md: pxToRem(224),
				lg: pxToRem(296),
				xl: pxToRem(348),
			},
			thumbHeight: {
				md: pxToRem(156),
				lg: pxToRem(168),
				xl: pxToRem(196),
			},
		},
		infoConfig: {
			isDark,
			titleClamp: 2,
			bodyClamp: 2,
			infoGap: { xs: pxToRem(12), md: pxToRem(8), xl: pxToRem(12) },
			infoSx: {
				paddingX: { xs: pxToRem(20), md: 0 },
				height: {
					md: pxToRem(156),
					lg: pxToRem(168),
					xl: pxToRem(196),
				},
				'.info-title': {
					fontSize: {
						xs: pxToRem(26),
						md: pxToRem(20),
						lg: pxToRem(22),
						xl: pxToRem(24),
					},
					lineHeight: {
						xs: pxToRem(34),
						md: pxToRem(26),
						lg: pxToRem(30),
						xl: pxToRem(32),
					},
					WebkitLineClamp: {
						md: 3,
						xl: 2,
					},
				},
				'.info-body': {
					WebkitLineClamp: {
						xl: 3,
					},
					fontSize: {
						xs: pxToRem(15),
					},
				},
				'.info-additionalBox': {
					marginTop: { xs: pxToRem(16), md: 'auto' },
				},
			},
		},
	}

	const mainCardProps2: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			flexDirection: { xs: 'row-reverse', md: 'row' },
			layoutGap: { xs: pxToRem(16), md: pxToRem(20), xl: pxToRem(24) },
		},
		thumbnailConfig: {
			thumbWidth: {
				xs: pxToRem(120),
				md: pxToRem(224),
				lg: pxToRem(296),
				xl: pxToRem(348),
			},
			thumbHeight: {
				xs: pxToRem(68),
				md: pxToRem(156),
				lg: pxToRem(168),
				xl: pxToRem(196),
			},
		},
		infoConfig: {
			isDark,
			titleClamp: 2,
			bodyClamp: 2,
			infoGap: { xs: pxToRem(8), xl: pxToRem(12) },
			infoSx: {
				height: {
					md: pxToRem(156),
					lg: pxToRem(168),
					xl: pxToRem(196),
				},
				'.info-title': {
					fontSize: {
						xs: pxToRem(16),
						md: pxToRem(20),
						lg: pxToRem(22),
						xl: pxToRem(24),
					},
					lineHeight: {
						xs: pxToRem(22),
						md: pxToRem(26),
						lg: pxToRem(30),
						xl: pxToRem(32),
					},
					WebkitLineClamp: {
						md: 3,
						xl: 2,
					},
					height: {
						xs: pxToRem(44),
						md: 'auto',
					},
				},
				'.info-body': {
					display: {
						xs: 'none',
						md: '-webkit-box',
					},
					WebkitLineClamp: {
						xl: 3,
					},
					fontSize: {
						xs: pxToRem(15),
					},
				},
				'.info-additionalBox': {
					marginTop: { md: 'auto' },
				},
			},
		},
	}
	const mainCardProps = [mainCardProps1, mainCardProps2]
	const subCardProps: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			flexDirection: { xs: 'row-reverse', lg: 'row' },
			layoutGap: pxToRem(16),
		},
		thumbnailConfig: {
			thumbWidth: { xs: pxToRem(120), xl: pxToRem(144) },
			thumbHeight: { xs: pxToRem(68), xl: pxToRem(82) },
			badgeInset: pxToRem(6),
			thumbSx: {
				display: {
					md: 'none',
					lg: 'block',
				},
			},
		},
		infoConfig: {
			isDark,
			titleLevel: 'HeadingS',
			infoSx: {
				'.info-title': {
					height: {
						xs: pxToRem(44),
						xl: pxToRem(48),
					},
					fontSize: {
						xs: pxToRem(16),
						xl: pxToRem(17),
					},
					lineHeight: {
						xs: pxToRem(22),
						xl: pxToRem(24),
					},
				},
				'.info-additionalText': {
					lineHeight: { xs: pxToRem(16), xl: pxToRem(18) },
				},
			},
		},
	}
	const createCardContent = (item: ArticleItemProps) => ({
		articleIdx: item?.articleIdx,
		src: item?.articleThumbnailImgUrl ?? undefined,
		href: getArticleLink(item, 'HOME', areaIdx),
		alt: '',
		duration: item?.vodInfo?.playTime,
		title: item?.articleTitle,
		additionalText: getRelativeTime(item?.publicationDate ?? '', item?.code === 'LIVE'),
		bullet: item?.bulletType && item.bulletType.name,
		bulletColor: item?.bulletType && getBulletColor(item.bulletType.name),
		showLiveBadge: item?.code === 'LIVE',
		liveTagTypeCode: item?.liveTagTypeCode,
		vodInfo: item?.vodInfo,
	})
	const drawMainArticleaItem = (item: ArticleItemProps, index: number) => (
		<Box
			gridArea={`main${index + 1}`}
			key={`ListMDimage1-${item.code}-main-item-${item.articleIdx}-${areaIdx}`}
		>
			<ContentCard
				content={{
					...createCardContent(item),
					body: item?.articleInnerTextContent ?? undefined,
				}}
				layoutConfig={mainCardProps[index].layoutConfig}
				thumbnailConfig={mainCardProps[index].thumbnailConfig}
				infoConfig={mainCardProps[index].infoConfig}
			/>
		</Box>
	)
	const drawSubArticleaItem = (item: ArticleItemProps, index: number) =>
		index < 4 && (
			<Box
				gridArea={`sub${index + 1}`}
				className="sub-item"
				key={`ListMDimage1-${item.code}-sub-item-${item.articleIdx}-${areaIdx}`}
			>
				<ContentCard
					content={createCardContent(item)}
					layoutConfig={subCardProps.layoutConfig}
					thumbnailConfig={subCardProps.thumbnailConfig}
					infoConfig={subCardProps.infoConfig}
				/>
			</Box>
		)
	return (
		<CSSgridList
			className="List-MDimage1"
			gridTemplateRows={{ md: 'repeat(4, 1fr)' }}
			gridTemplateColumns={{
				xs: '1fr',
				md: 'repeat(3, 1fr)',
				lg: `repeat(2, ${pxToRem(296)}) ${pxToRem(368)}`,
				xl: 'repeat(3, 1fr)',
			}}
			gridTemplateAreas={{
				xs: `
						"main1"
						"main2"
						"sub1"
						"sub2"
						"sub3"
						"sub4"
					`,
				md: `
						"main1 main1 sub1"
						"main1 main1 sub2"
						"main2 main2 sub3"
						"main2 main2 sub4"
					`,
			}}
			overflow={{ xs: 'visible', md: 'hidden' }}
			isDark={isDark}
			sx={{
				[theme.breakpoints.down('md')]: {
					...CSSGridListBorder('horizontal', isDark),
					'.sub-item:nth-child(5)': {
						paddingBottom: 0,
						borderBottom: 0,
					},
					'.sub-item:nth-child(5) ~ .sub-item': {
						display: 'none',
					},
				},
				[theme.breakpoints.up('md')]: {
					...CSSGridListBorder('both', isDark),
					[theme.breakpoints.only('md')]: {
						'>*:not(.sub-item)': {
							paddingBottom: pxToRem(16),
							'>*': {
								paddingTop: pxToRem(15),
							},
							'&:first-of-type': {
								marginTop: pxToRem(-4),
							},
							'&:nth-of-type(2)': {
								marginBottom: pxToRem(-4),
							},
						},
					},
				},
			}}
		>
			{mainList?.map(drawMainArticleaItem)}
			{subList?.map(drawSubArticleaItem)}
		</CSSgridList>
	)
}

export default ListMDimage1
