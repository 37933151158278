'use client'

import { BannerVo, CommonAreaResponseVo, HomeAreaSequenceResponseVo } from '@schemas/non-auth'
import Section from '@components/common/Section/Section'
import ListBanner from '@components/list/ListBanner'
import UIContainer from '@components/ui/UIContainer'
import SectionTitle from '@components/common/Section/SectionTitle'
import { useSearchParams } from 'next/navigation'
import { useQuery } from '@tanstack/react-query'
import { queryBanner } from '@services/api/NewsHome/Banner'

export type BannerSectionData = HomeAreaSequenceResponseVo & CommonAreaResponseVo
interface SectionProps {
	data: BannerSectionData
}

const BannerSection = ({ data }: SectionProps) => {
	const param = useSearchParams()
	const previewBannerIdx = param.get('previewBannerIdx')
	const { areaItemList, bgMoImgUrl, bgTbImgUrl, bgPcImgUrl, isStaticBackgroundColor } = data
	const src = bgMoImgUrl && bgTbImgUrl && bgPcImgUrl && { bgMoImgUrl, bgTbImgUrl, bgPcImgUrl }
	const { data: resBanner } = useQuery(
		queryBanner({
			areaIdx: data.areaIdx,
			previewBannerIdx: previewBannerIdx || undefined,
		}),
	)

	return (
		<Section
			bgColor={isStaticBackgroundColor}
			{...(!isStaticBackgroundColor && src && { src, className: 'set-bg' })}
		>
			<UIContainer>
				{data.areaName && data.areaName !== '' && (
					<SectionTitle
						isDark={!!src && !isStaticBackgroundColor}
						showLine={!src && !isStaticBackgroundColor}
					>
						{data.areaName}
					</SectionTitle>
				)}
				<ListBanner
					list={
						(resBanner?.data?.areaItemList as unknown as Array<BannerVo>) ||
						(areaItemList as unknown as Array<BannerVo>)
					}
					areaIdx={data.areaIdx}
				/>
			</UIContainer>
		</Section>
	)
}
export default BannerSection
