import {
	CommonAreaResponseVo,
	HomeAreaSequenceResponseVo,
	LiveScheduleResponseVo,
} from '@schemas/non-auth'
import Section from '@components/common/Section/Section'
import UIContainer from '@components/ui/UIContainer'
import ListTemplate from '@components/list'
import getTemplateData from '@utils/getTemplateData'

export type NewsImportantSectionData = HomeAreaSequenceResponseVo & CommonAreaResponseVo
interface SectionProps {
	data: NewsImportantSectionData
	scheduleList: Array<LiveScheduleResponseVo>
}

const NewsImportantSection = ({ data }: SectionProps) => {
	const { areaItemList, issueInfo, bgMoImgUrl, bgTbImgUrl, bgPcImgUrl, isStaticBackgroundColor } =
		data
	const src = bgMoImgUrl && bgTbImgUrl && bgPcImgUrl && { bgMoImgUrl, bgTbImgUrl, bgPcImgUrl }
	const artcleList = getTemplateData(areaItemList)
	return (
		(artcleList?.length ?? 0) > 0 && (
			<Section
				bgColor={isStaticBackgroundColor}
				{...(!isStaticBackgroundColor && src && { src, className: 'set-bg' })}
			>
				<UIContainer>
					<ListTemplate
						type="article"
						list={artcleList}
						issue={issueInfo}
						isDark={!!src && !isStaticBackgroundColor}
						idx={data.templateIdx}
						areaIdx={data.areaIdx}
					/>
				</UIContainer>
			</Section>
		)
	)
}
export default NewsImportantSection
