'use client'

import { ReactNode, useRef } from 'react'
import ContentBadge from '@components/content/ContentBadge'
import UIFlex, { UIFlexProps } from '@components/ui/UIFlex'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import getBulletColor, { BulletName } from '@utils/getBulletColor'

interface SectionDetailTitleProps {
	sx?: UIFlexProps['sx']
	title: string
	body?: string
	additionalText?: string
	isDark?: boolean
	isLive?: boolean
	liveTagTypeCode?: string
	href?: string
	hLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
	bullet?: BulletName
}
const SectionDetailTitle = ({
	isDark,
	isLive,
	liveTagTypeCode,
	title,
	body,
	additionalText,
	href,
	hLevel = 'h2',
	sx,
	bullet,
}: SectionDetailTitleProps) => {
	const additionText = useRef<ReactNode>(additionalText)
	return (
		<UIFlex
			{...(href && { href, component: UILink })}
			width={{ xs: '100%', md: pxToRem(348), lg: pxToRem(448), xl: pxToRem(592) }}
			alignItems="flex-start"
			sx={{
				...sx,
				'&> *:not(:first-child)': { marginTop: { xs: pxToRem(10), md: pxToRem(12) } },
			}}
		>
			{isLive && liveTagTypeCode && (
				<ContentBadge contentType="LIVE" liveTagTypeCode={liveTagTypeCode} />
			)}
			<UIText
				component={hLevel}
				color={isDark ? color.colWhite : color.colBlack}
				lineClamp={2}
				fontSize={{
					xs: pxToRem(26),
					lg: pxToRem(28),
					xl: pxToRem(34),
				}}
				lineHeight={{
					xs: pxToRem(34),
					lg: pxToRem(36),
					xl: pxToRem(44),
				}}
				fontWeight={700}
			>
				{bullet && <UIText color={getBulletColor(bullet)}>{bullet}&nbsp;&nbsp;</UIText>}
				{title}
			</UIText>
			{body && (
				<UIText
					color={isDark ? color.colWhite : color.colGray5}
					lineClamp={2}
					fontSize={pxToRem(15)}
					lineHeight={pxToRem(22)}
					sx={{ opacity: isDark ? 0.7 : 1 }}
				>
					{body}
				</UIText>
			)}
			<UIText
				color={isDark ? color.colWhite : color.colGray5}
				fontSize={pxToRem(15)}
				lineHeight={pxToRem(18)}
				sx={{ opacity: isDark ? 0.7 : 1 }}
			>
				{additionalText && additionText.current}
			</UIText>
		</UIFlex>
	)
}

export default SectionDetailTitle
