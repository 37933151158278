/**
 * @name date
 * @description dayjs에 대한 전역설정, dayjs를 date 변수에 저장 후 export
 */

/**
 * @note
 * ~59초 : 1분 미만
 * ~59분 : N분 전
 * ~23시간 : N시간 전
 * ~2일 : N일 전
 * 3일~ : yyyy.mm.dd
 */

import originalDayjs, { type Dayjs as OriginalDayjs } from 'dayjs'
import ko from 'dayjs/locale/ko'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'

export const DATE_FORMAT = {
	FULL: 'YYYY년 MM월 DD일 A h시 mm분',
	FULL_AS_NUMBER: 'YYYYMMDDHHmmss',
	DATE: 'YYYY.MM.DD',
	DATE_TIME_MIN: 'YYYY.MM.DD HH:mm',
	DATE_FULL: 'YYYY년 MM월 DD일',
	DATE_FULL_WITH_WEEKDAY: 'YYYY년 MM월 DD일 ddd요일',
	DATE_AS_NUMBER: 'YYYYMMDD',
	DATE_SIMPLE: 'YYMMDD',
	DATE_WITH_WEEKDAY: 'YYYY.MM.DD (ddd)',
	TIME: 'HH:mm:ss',
	TIME_MIN: 'HH:mm',
	TIME_AS_A: 'A h시 mm분',
	WITH_ZONE: 'YYYY.MM.DD HH:mm:ss Z',
	CLIENT: 'YYYY.MM.DD HH:mm:ss',
	CLIENT_MIN: 'YYYY.MM.DD HH:mm',
}

originalDayjs.locale(ko)
originalDayjs.extend(relativeTime, {
	thresholds: [
		{ l: 's', r: 1 },
		{ l: 'm', r: 1 },
		{ l: 'mm', r: 59, d: 'minute' },
		{ l: 'h', r: 1 },
		{ l: 'hh', r: 23, d: 'hour' },
		{ l: 'd', r: 1 },
		{ l: 'dd', r: 29, d: 'day' },
		{ l: 'M', r: 1 },
		{ l: 'MM', r: 11, d: 'month' },
		{ l: 'y', r: 1 },
		{ l: 'yy', d: 'year' },
	],
})
originalDayjs.extend(updateLocale)
originalDayjs.updateLocale('ko', {
	relativeTime: {
		...originalDayjs.Ls.ko.relativeTime,
		past: (result: string) => {
			return result.includes('미만') ? result : `${result} 전`
		},
		s: '1분 미만',
		d: '1일',
	},
})

export type Dayjs = OriginalDayjs

export const dayjs = originalDayjs

export const getRelativeTime = (date: Dayjs | string, isLive?: boolean) => {
	if (!date) {
		return ''
	}
	const current = dayjs()
	if (!isLive) {
		const target = dayjs(date)
		if (current.diff(target, 'days') > 2) {
			return dayjs(date).format(DATE_FORMAT.DATE).replace('한 ', '1')
		}
		return target.fromNow(false).replace('한 ', '1')
	}
	const schedule = dayjs(date)
	const calcDate = schedule.diff(current, 'day')
	if (calcDate === 0) {
		return schedule.format(`${DATE_FORMAT.TIME_MIN}~`).replace('한 ', '1')
	}

	return schedule.format(`${DATE_FORMAT.CLIENT_MIN}~`).replace('한 ', '1')
}

export const formatRelativeTime = (date: Dayjs | string, format?: string) => {
	if (!date) {
		return ''
	}
	const current = dayjs()
	const target = dayjs(date)
	if (current.diff(target, 'days') > 2) {
		return dayjs(date)
			.format(format ?? DATE_FORMAT.DATE_TIME_MIN)
			.replace('한 ', '1')
	}
	return target.fromNow(false).replace('한 ', '1')
}

/**
 * @description 주어진 날짜가 현재 기준으로 24시간 이전인지 확인
 * @param dateStr - 'YYYY-MM-DDTHH:mm' 형식의 날짜 문자열
 * @returns 날짜가 24시간 이전이면 true, 그렇지 않으면 false를 반환
 */
export function isWithinLast24Hours(dateStr: string): boolean {
	const now = new Date()
	const inputDate = new Date(dateStr)
	const timeDifference = now.getTime() - inputDate.getTime()
	const hoursDifference = timeDifference / (1000 * 60 * 60)
	return hoursDifference < 24
}

//
