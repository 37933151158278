import {
	CommonAreaResponseVo,
	HomeAreaSequenceResponseVo,
	LiveScheduleResponseVo,
} from '@schemas/non-auth'
import Section from '@components/common/Section/Section'
import SectionTitle from '@components/common/Section/SectionTitle'
import UIContainer from '@components/ui/UIContainer'
import ListTemplate, { ArticleItemProps } from '@components/list'
import getTemplateData from '@utils/getTemplateData'
import { DATE_FORMAT, dayjs } from '@utils/date'
import { useLive } from '@services/api/NewsHome/Live/query'
import NewsScheduleSection from '../NewsImportant/NewsSchedule'
import { Box } from '@mui/joy'
import pxToRem from '@utils/pxToRem'

export type LiveSectionData = HomeAreaSequenceResponseVo & CommonAreaResponseVo
interface SectionProps {
	data: LiveSectionData
	isLive?: boolean
	scheduleList: Array<LiveScheduleResponseVo>
}

const LiveSection = ({ data, isLive, scheduleList }: SectionProps) => {
	const { data: liveData } = useLive({ areaIdx: data.areaIdx })
	const { areaItemList, issueInfo, bgMoImgUrl, bgTbImgUrl, bgPcImgUrl, isStaticBackgroundColor } =
		data
	const src = bgMoImgUrl && bgTbImgUrl && bgPcImgUrl && { bgMoImgUrl, bgTbImgUrl, bgPcImgUrl }
	const getPublicTime = (item: ArticleItemProps) =>
		Number(dayjs(item.publicationDate).format(DATE_FORMAT.FULL_AS_NUMBER))
	const articleList = getTemplateData(liveData?.areaItemList ?? areaItemList)

	const renderLiveContent = () => {
		if (!isLive || articleList.length === 0) return null

		return (
			<UIContainer>
				{![2, 4, 22].includes(data.templateIdx) && (
					<SectionTitle
						href="/live"
						showGoButton
						isDark={!!src && !isStaticBackgroundColor}
						showLine={!src && !isStaticBackgroundColor}
					>
						실시간 라이브
					</SectionTitle>
				)}
				<ListTemplate
					type="live"
					list={articleList}
					issue={issueInfo}
					isDark={!!src && !isStaticBackgroundColor}
					idx={data.templateIdx}
					areaIdx={data.areaIdx}
				/>
			</UIContainer>
		)
	}

	return (
		<Section
			bgColor={isStaticBackgroundColor}
			{...(!isStaticBackgroundColor && src && { src, className: 'set-bg' })}
			sx={{
				// 실시간라이브/뉴스편성표만 간격 다름
				marginTop: {
					xl: `${pxToRem(64)}!important`,
				},
			}}
		>
			{renderLiveContent()}
			{articleList.length === 0 && (
				<Box sx={{ margin: { xs: `${pxToRem(-42)} 0`, md: `${pxToRem(-48)} 0` } }}>
					<NewsScheduleSection scheduleList={scheduleList} />
				</Box>
			)}
		</Section>
	)
}
export default LiveSection
