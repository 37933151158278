import { ArticleItemProps } from '@components/list'
import { AreaItem } from '@schemas/non-auth'

const getTemplateData = (list: Array<AreaItem>): Array<ArticleItemProps> => {
	const computeTemplateData = (item: AreaItem) => {
		return {
			...(item.itemCode.code === 'ARTICLE' && {
				...item.articleInfo,
				articleTitle: item.itemTitle ?? item.articleInfo.articleTitle,
				articleInnerTextContent:
					item?.itemContent ?? item.articleInfo.articleInnerTextContent,
			}),
			...(item.itemCode.code === 'LIVE' && {
				articleIdx: item.liveInfo.liveIdx?.toString(),
				articleTitle: item.itemTitle ?? item.liveInfo?.liveTitle,
				articleInnerTextContent: item?.itemContent,
				publicationDate: item.liveInfo.liveStartDate,
				articleThumbnailImgUrl: item.liveInfo.liveThumbnailImgUrl,
				bulletType: item.liveInfo.liveBulletTypeCode,
				liveTagTypeCode: item.liveInfo.liveTagTypeCode,
			}),
			...(item.itemCode.code === 'BANNER' && {
				articleIdx: item.bannerInfo.bannerIdx?.toString(),
				articleTitle: item.itemTitle ?? item.bannerInfo?.bannerTitle,
				articleInnerTextContent: item?.itemContent,
				publicationDate: item.bannerInfo.bannerStartDate,
				articleThumbnailImgUrl: item.bannerInfo.bannerThumbnailImgUrl,
				linkUrlInfo: item.bannerInfo.linkUrlInfo,
			}),
			articleTitle: item.itemTitle,
			code: item.itemCode.code,
		} as ArticleItemProps
	}
	return list?.map(computeTemplateData)
}
export default getTemplateData
