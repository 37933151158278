'use client'

import { Box, Grid } from '@mui/joy'
import ContentCard, { ContentCardProps } from '@components/content/ContentCard'
import { pxToRem } from '@ui/style/muiTheme'
import UIDivider from '@components/ui/UIDivider'
import LargeContentCard, { LargeContentCardProps } from '@components/content/LargeContentCard'
import { theme } from '@ui/style'
import color from '@ui/style/color.theme'
import { getRelativeTime } from '@utils/date'
import getBulletColor from '@utils/getBulletColor'
import CSSgridList from '@components/list/CSSgridList'
import { CSSGridListBorder } from '@components/list/CSSgridList/CSSgridListRoot'
import { insetLayoutStyle } from '@components/content/LargeContentCard/ContentInfoWrapper'
import { ArticleItemProps } from '..'
import { getArticleLink } from '@utils/getLink'
import { imgDefaultH } from 'src/shared/variables'
import ContentBadge from '@components/content/ContentBadge'

const ListLgimageList = ({
	list = [],
	isDark,
	areaIdx,
}: {
	list: Array<ArticleItemProps>
	isDark?: boolean
	areaIdx?: number
}) => {
	const [article1st, ...articleList] = list

	const largeCardProps: Omit<LargeContentCardProps, 'content'> = {
		layoutConfig: {
			flexDirection: {
				xs: 'column',
				md: 'row',
				lg: 'column',
			},
			layoutGap: {
				md: pxToRem(24),
				lg: pxToRem(16),
			},
			layoutSx: {
				margin: { xs: `0 -${pxToRem(20)}`, md: 0 },
				width: { lg: '100%' },
				'.largeContentCard-wrapper': {
					flexGrow: 1,
				},
				'.largeContentCard-extraContent': {
					display: 'none',
				},
				[theme.breakpoints.down('md')]: {
					'.largeContentCard-wrapper': {
						...insetLayoutStyle,
						justifyContent: 'flex-end',
						'.content-info-box': {
							order: 1,
							flex: '0 0 0',
							paddingTop: pxToRem(12),
							flexDirection: 'column',
						},
						'.largeContentCard-extraContent': {
							display: 'flex',
							order: 0,
							paddingY: 0,
							alignItems: 'flex-start',
							'.contentBadge': {
								padding: `${pxToRem(6)} ${pxToRem(9)}`,
								'&_text': {
									fontSize: pxToRem(16),
									lineHeight: pxToRem(18),
									svg: {
										width: pxToRem(8),
										height: pxToRem(8),
										'&+span': {
											marginLeft: pxToRem(6),
										},
									},
								},
							},
						},
					},
				},
				[theme.breakpoints.up('xl')]: {
					'.largeContentCard-wrapper': {
						...insetLayoutStyle,
						justifyContent: 'flex-end',
						'.content-info-box': {
							order: 1,
							flex: '0 0 0',
							paddingTop: pxToRem(12),
							flexDirection: 'column',
						},
						'.largeContentCard-extraContent': {
							display: 'flex',
							order: 0,
							paddingY: 0,
							alignItems: 'flex-start',
							'.contentBadge': {
								padding: `${pxToRem(6)} ${pxToRem(9)}`,
								'&_text': {
									fontSize: pxToRem(16),
									lineHeight: pxToRem(18),
									svg: {
										width: pxToRem(8),
										height: pxToRem(8),
										'&+span': {
											marginLeft: pxToRem(6),
										},
									},
								},
							},
						},
					},
				},
			},
		},
		thumbnailConfig: {
			badgeInset: { xs: pxToRem(8), lg: pxToRem(20) },
			thumbRatio: '1',
			thumbWidth: { xs: '100%', md: pxToRem(348), lg: '100%' },
			thumbSx: {
				'.thumb-liveBadge': {
					[theme.breakpoints.down('md')]: {
						display: 'none',
					},
					[theme.breakpoints.up('lg')]: {
						bottom: pxToRem(8),
						right: pxToRem(8),
						'.contentBadge': {
							padding: `${pxToRem(6)} ${pxToRem(9)}`,
							'&_text': {
								fontSize: pxToRem(16),
								lineHeight: pxToRem(18),
								svg: {
									width: pxToRem(8),
									height: pxToRem(8),
									'&+span': {
										marginLeft: pxToRem(6),
									},
								},
							},
						},
					},
					[theme.breakpoints.up('xl')]: {
						display: 'none',
					},
				},
				'.MuiAspectRatio': {
					[theme.breakpoints.up('md')]: {
						'&-content': {
							paddingBottom: '56.3%',
						},
					},
					[theme.breakpoints.up('lg')]: {
						'&-content': {
							paddingBottom: '61.1%',
						},
					},
					[theme.breakpoints.up('xl')]: {
						'&-content': {
							paddingBottom: '53%',
						},
					},
				},
			},
		},
		infoConfig: {
			isDark,
			titleLevel: 'DisplayXL',
			bodyClamp: 3,
			titleClamp: 3,
			infoSx: {
				'.info-body': {
					display: 'none',
				},
				'.info-additionalBox': {
					marginTop: pxToRem(12),
				},
				[theme.breakpoints.up('md')]: {
					'.info-additionalBox': {
						marginBottom: '0!important',
						marginTop: 'auto!important',
					},
					'.info-body': {
						display: '-webkit-box!important',
					},
				},
				[theme.breakpoints.up('lg')]: {
					'.info-additionalBox': {
						marginTop: `${pxToRem(12)}!important`,
					},
					'.info-body': {
						display: 'none!important',
					},
					'.info-additionalText': {
						fontSize: pxToRem(15),
					},
					'.info-title': {
						WebkitLineClamp: 2,
					},
				},
				[theme.breakpoints.up('xl')]: {
					'.info-additionalBox': {
						marginTop: `${pxToRem(16)}!important`,
						marginBottom: `0!important`,
					},
					'.info-title': {
						width: pxToRem(604),
					},
				},
			},
		},
	}

	const cardProps: Omit<ContentCardProps, 'content'> = {
		layoutConfig: {
			flexDirection: 'row-reverse',
			layoutGap: pxToRem(16),
			layoutSx: {
				height: {
					lg: pxToRem(74),
					xl: pxToRem(82),
				},
				alignItems: 'flex-start',
			},
		},
		thumbnailConfig: {
			thumbWidth: { xs: pxToRem(120), lg: pxToRem(144) },
			thumbHeight: { xs: pxToRem(68), lg: pxToRem(74), xl: pxToRem(82) },
			badgeInset: pxToRem(6),
		},
		infoConfig: {
			isDark,
			infoGap: pxToRem(8),
			infoSx: {
				alignItems: 'flex-start',
				'.info-title': {
					fontSize: {
						xs: pxToRem(16),
						lg: pxToRem(17),
					},
					lineHeight: {
						xs: pxToRem(22),
						lg: pxToRem(24),
					},
					// marginBottom: pxToRem(8),
				},
			},
		},
	}

	const mdBottomStyles = {
		position: 'relative',
		'&::after': {
			content: '""',
			display: { xs: 'none', md: 'block', lg: 'none' },
			position: 'absolute',
			left: pxToRem(12),
			right: pxToRem(12),
			bottom: 0,
			height: '1px',
			background: isDark ? 'rgba(255,255,255,0.2)' : color.colGray3,
		},
	}
	const createCardContent = (item: ArticleItemProps, isLargeCard = false) => ({
		articleIdx: item?.articleIdx,
		src: item?.articleThumbnailImgUrl ?? undefined,
		href: getArticleLink(item, 'HOME', areaIdx),
		alt: '',
		title: item.articleTitle,
		additionalText: getRelativeTime(item?.publicationDate ?? '', item?.code === 'LIVE'),
		duration: !isLargeCard ? item?.vodInfo?.playTime : undefined,
		showVideoViewIcon: !isLargeCard ? item?.code !== 'LIVE' && item?.isVideoView : false,
		bullet: item?.bulletType && item.bulletType.name,
		bulletColor: item?.bulletType && getBulletColor(item.bulletType.name),
		showLiveBadge: item?.code === 'LIVE',
		liveTagTypeCode: item?.liveTagTypeCode,
		vodInfo: item?.vodInfo,
	})
	const drawArticleItem = (item: ArticleItemProps, index: number) =>
		index < 4 && (
			<Box
				key={`${item.code ?? 'article'}-item-${item.articleIdx}`}
				sx={index % 2 === 1 ? mdBottomStyles : undefined}
			>
				<ContentCard
					content={createCardContent(item)}
					layoutConfig={cardProps.layoutConfig}
					thumbnailConfig={cardProps.thumbnailConfig}
					infoConfig={cardProps.infoConfig}
				/>
			</Box>
		)
	return (
		<Grid
			container
			columnSpacing={{ xs: 0, md: pxToRem(24), lg: pxToRem(32) }}
			rowSpacing={{ xs: pxToRem(16), md: pxToRem(24), lg: 0 }}
			alignItems="flex-start"
			className="list-LGimageList"
		>
			<Grid
				xs={12}
				md={12}
				lg={6}
				xl={8}
				display={{ xs: 'block', md: 'flex' }}
				alignItems="stretch"
			>
				{article1st && (
					<LargeContentCard
						content={{
							...createCardContent(article1st, true),
							body: article1st.articleInnerTextContent || '',
						}}
						layoutConfig={largeCardProps.layoutConfig}
						thumbnailConfig={largeCardProps.thumbnailConfig}
						infoConfig={largeCardProps.infoConfig}
						isInsetLayout={largeCardProps.isInsetLayout}
						extraContent={
							article1st.code === 'LIVE' &&
							article1st.liveTagTypeCode && (
								<ContentBadge
									contentType="LIVE"
									liveTagTypeCode={article1st.liveTagTypeCode}
								/>
							)
						}
					/>
				)}
			</Grid>
			<UIDivider
				orientation="vertical"
				sx={{ display: { xs: 'none', lg: 'block' } }}
				isDark={isDark}
			/>
			<Grid xs={12} md={12} lg={6} xl={4}>
				<CSSgridList
					gridTemplateRows={{ md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
					gridTemplateColumns={{ xs: '1fr', md: 'repeat(2, 1fr)', lg: '1fr' }}
					gridAutoFlow={{ xs: 'row', md: 'column' }}
					my={{ md: '0!important', lg: `${pxToRem(-16)}!important` }}
					isDark={isDark}
					sx={{
						[theme.breakpoints.down('md')]: {
							...CSSGridListBorder('horizontal', isDark),
						},
						[theme.breakpoints.up('md')]: {
							...CSSGridListBorder('both', isDark),
						},
					}}
				>
					{articleList?.map(drawArticleItem)}
				</CSSgridList>
			</Grid>
		</Grid>
	)
}

export default ListLgimageList
