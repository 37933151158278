'use client'

import {
	LiveSection,
	VideoNewsSection,
	RankNewsSection,
	ShortformNewsSection,
	RecommendedNewsSection,
	SubscribedIssueSection,
	RecommendedIssueSection,
	TodaysJournalistSection,
	SeriesCornerSection,
	SectorNewsSection,
	CorrectionRebuttalSection,
	BannerSection,
	SelectionSection,
	NewsImportantSection,
	IssueTopicSection,
} from './Section'
import { LiveSectionData } from './Section/Live'
import { NewsImportantSectionData } from './Section/NewsImportant'
import { IssueTopicSectionData } from './Section/IssueTopic'
import { VideoNewsSectionData } from './Section/VideoNews'
import { RankNewsSectionData } from './Section/RankNews'
import { ShortformNewsSectionData } from './Section/ShortformNews'
import { RecommendedNewsSectionData } from './Section/RecommendedNews'
import { SubscribedIssueSectionData } from './Section/SubscribedIssue'
import { RecommendedIssueSectionData } from './Section/RecommendedIssue'
import { TodaysJournalistSectionData } from './Section/TodaysJournalist'
import { SeriesCornerSectionData } from './Section/SeriesCorner'
import { SectorNewsSectionData } from './Section/SectorNews'
import { CorrectionRebuttalSectionData } from './Section/CorrectionRebuttal'
import { BannerSectionData } from './Section/Banner'
import { SelectionSectionData } from './Section/Selection'
import { homeDivIdx } from '@services/api/NewsHome'
import { DehydratedState } from '@tanstack/react-query'
import isFailedAPI from '@utils/isFailedAPI'
import { LiveScheduleResponseVo } from '@schemas/non-auth'

export type MainSectionData =
	| LiveSectionData
	| NewsImportantSectionData
	| IssueTopicSectionData
	| VideoNewsSectionData
	| RankNewsSectionData
	| ShortformNewsSectionData
	| RecommendedNewsSectionData
	| SubscribedIssueSectionData
	| RecommendedIssueSectionData
	| TodaysJournalistSectionData
	| SeriesCornerSectionData
	| SectorNewsSectionData
	| CorrectionRebuttalSectionData
	| BannerSectionData
	| SelectionSectionData

interface FallbackDataSet {
	dehydratedState: DehydratedState
	data: CommonResponse<MainSectionData>
}
interface MainSectionProps {
	data: MainSectionData & Partial<FallbackDataSet>
	isLive?: boolean
	scheduleList: Array<LiveScheduleResponseVo>
}
const MainSection = ({ data, isLive, scheduleList }: MainSectionProps) => {
	if (isFailedAPI(data)) {
		return null
	}
	switch (data.divValueIdx) {
		case homeDivIdx.live:
			return (
				<LiveSection
					data={data as LiveSectionData}
					isLive={isLive}
					scheduleList={scheduleList}
				/>
			)
		case homeDivIdx.newsImportant:
			return (
				<NewsImportantSection
					data={data as NewsImportantSectionData}
					scheduleList={scheduleList}
				/>
			)
		case homeDivIdx.issueTopic:
			return <IssueTopicSection data={data as IssueTopicSectionData} />
		case homeDivIdx.videoNews:
			return <VideoNewsSection data={data as VideoNewsSectionData} />
		case homeDivIdx.rankNews:
			return <RankNewsSection data={data as RankNewsSectionData} />
		case homeDivIdx.shortsNews:
			return <ShortformNewsSection data={data as ShortformNewsSectionData} />
		case homeDivIdx.recommendNews:
			return <RecommendedNewsSection data={data as RecommendedNewsSectionData} />
		case homeDivIdx.subscribeIssue:
			return <SubscribedIssueSection data={data as SubscribedIssueSectionData} />
		case homeDivIdx.journalist:
			return <TodaysJournalistSection data={data as TodaysJournalistSectionData} />
		case homeDivIdx.series:
			return <SeriesCornerSection data={data as SeriesCornerSectionData} />
		case homeDivIdx.sectionNews:
			return <SectorNewsSection data={data as SectorNewsSectionData} />
		case homeDivIdx.correctionRebuttal:
			return <CorrectionRebuttalSection data={data as CorrectionRebuttalSectionData} />
		case homeDivIdx.banner:
			return <BannerSection data={data as BannerSectionData} />
		case homeDivIdx.recommendIssue:
			return <RecommendedIssueSection data={data as RecommendedIssueSectionData} />
		default:
			return <SelectionSection data={data as SelectionSectionData} />
	}
}
export default MainSection
