'use client'

import { ArticleListResponseVo, FrontUrlLinkResponseVo, IssueInfo } from '@schemas/non-auth'
import ListMDimage1 from './ListMDimage1'
import ListMDimage2 from './ListMDimage2'
import ListMDimage3 from './ListMDimage3'
import ListOnBg from './ListOnBg'
import ListTimeline from './ListTimeline'
import List3XN from './List3XN'
import List2XN from './List2XN'
import List4XN from './List4XN'
import ListLgimageList from './ListLGimageList'
import ListLgimageIssue from './ListLGimageIssue'
import ListLgimageRelated from './ListLGimageRelated'
import ListOnBgHighlight from './ListOnBgHighlight'

export type ArticleItemProps = ArticleListResponseVo & {
	code?: string
	linkUrlInfo?: FrontUrlLinkResponseVo
	liveTagTypeCode?: string
}
interface ListTemplateProps {
	type?: string
	list?: Array<ArticleListResponseVo | ArticleItemProps>
	issue?: IssueInfo
	isDark?: boolean
	idx?: number
	areaIdx?: number
	packageIdx?: string
	isVideoNews?: boolean
}
const ListTemplate = ({
	type,
	list = [],
	issue,
	isDark = false,
	idx = 1,
	areaIdx,
	packageIdx,
	isVideoNews = false,
}: ListTemplateProps) => {
	switch (idx) {
		case 1:
			return <ListLgimageList list={list} isDark={isDark} areaIdx={areaIdx} />
		case 2:
			return (
				<ListLgimageIssue
					list={list}
					issue={issue}
					isDark={isDark}
					type={type}
					areaIdx={areaIdx}
				/>
			)
		case 3:
			return <ListLgimageRelated list={list} isDark={isDark} areaIdx={areaIdx} />
		case 4:
			return (
				<ListOnBgHighlight
					list={list}
					issue={issue}
					isDark={isDark}
					type={type}
					areaIdx={areaIdx}
				/>
			)
		case 5:
			return <ListMDimage1 list={list} isDark={isDark} areaIdx={areaIdx} />
		case 6:
			return <ListMDimage2 list={list} isDark={isDark} areaIdx={areaIdx} />
		case 7:
			return (
				<ListMDimage3
					list={list}
					isDark={isDark}
					areaIdx={areaIdx}
					isVideoNews={isVideoNews}
					packageIdx={packageIdx}
				/>
			)
		case 8:
			return <ListOnBg list={list} isDark={isDark} areaIdx={areaIdx} />
		case 9:
			return <ListTimeline list={list} isDark={isDark} areaIdx={areaIdx} />
		case 10:
			return (
				<List3XN
					list={list}
					isDark={isDark}
					areaIdx={areaIdx}
					isVideoNews={isVideoNews}
					packageIdx={packageIdx}
				/>
			)
		case 11:
			return (
				<List2XN
					list={list}
					isDark={isDark}
					areaIdx={areaIdx}
					isVideoNews={isVideoNews}
					packageIdx={packageIdx}
				/>
			)
		case 12:
			return (
				<List4XN
					list={list}
					isDark={isDark}
					areaIdx={areaIdx}
					isVideoNews={isVideoNews}
					packageIdx={packageIdx}
				/>
			)
		case 22:
			return (
				<ListOnBgHighlight
					list={list}
					issue={issue}
					isDark={isDark}
					type={type}
					areaIdx={areaIdx}
				/>
			)
		case 23:
			return (
				<List3XN
					list={list}
					isDark={isDark}
					areaIdx={areaIdx}
					isVideoNews={isVideoNews}
					packageIdx={packageIdx}
				/>
			)
		case 24:
			return (
				<List2XN
					list={list}
					isDark={isDark}
					areaIdx={areaIdx}
					isVideoNews={isVideoNews}
					packageIdx={packageIdx}
				/>
			)
		case 25:
			return (
				<List4XN
					list={list}
					isDark={isDark}
					areaIdx={areaIdx}
					isVideoNews={isVideoNews}
					packageIdx={packageIdx}
				/>
			)
		default:
			return null
	}
}
export default ListTemplate
