import { useEffect, useState } from 'react'
import {
	VideoNewsAreaResponseVo,
	HomeAreaSequenceResponseVo,
	ProgramAreaItem,
} from '@schemas/non-auth'
import SectionTitle from '@components/common/Section/SectionTitle'
import UIContainer from '@components/ui/UIContainer'
import UIFlex from '@components/ui/UIFlex'
import { pxToRem } from '@ui/style/muiTheme'
import UISlideTab from '@components/ui/UITab'
import { SeeAllButton } from '@components/button/SeeAllButton'
import UILink from '@components/ui/UILink'
import ListTemplate from '@components/list'
import Section from '@components/common/Section/Section'
import { Box } from '@mui/joy'
import { getAreaParams } from '@utils/getLink'

export type VideoNewsSectionData = HomeAreaSequenceResponseVo & VideoNewsAreaResponseVo
interface SectionProps {
	data: VideoNewsSectionData
}

const VideoNewsSection = ({ data }: SectionProps) => {
	const {
		programAreaItemList: sectionList,
		bgMoImgUrl,
		bgTbImgUrl,
		bgPcImgUrl,
		isStaticBackgroundColor,
	} = data
	const src = bgMoImgUrl && bgTbImgUrl && bgPcImgUrl && { bgMoImgUrl, bgTbImgUrl, bgPcImgUrl }

	const [current, setCurrent] = useState<number>(0)
	const [program, setProgram] = useState<ProgramAreaItem>()
	useEffect(() => {
		setProgram(sectionList?.[current])
	}, [sectionList, current])

	return (
		(sectionList?.length ?? 0) > 0 && (
			<Section
				bgColor={isStaticBackgroundColor}
				{...(!isStaticBackgroundColor && src && { src, className: 'set-bg' })}
			>
				<UIContainer>
					{data.areaName && data.areaName !== '' && (
						<SectionTitle
							href="/program"
							showGoButton
							isDark={!!src && !isStaticBackgroundColor}
							showLine={!src && !isStaticBackgroundColor}
						>
							{data.areaName}
						</SectionTitle>
					)}
					<UIFlex
						sx={{
							'&>*:not(:first-child)': {
								marginTop: pxToRem(24),
							},
						}}
					>
						<Box mx={{ xs: '-20px', md: 0 }} className="tab-wrapper">
							<UISlideTab
								button
								options={sectionList?.map((option, index) => ({
									label: option.itemTitle,
									value: index,
								}))}
								current={current}
								setCurrent={(value) => setCurrent(value as number)}
							/>
						</Box>
						<UIFlex
							sx={{
								'&>*:not(:first-child)': {
									marginTop: pxToRem(32),
								},
							}}
						>
							<ListTemplate
								list={program?.programArticlelist.map((article) => ({
									...article,
									code: 'ARTICLE',
								}))}
								isDark={!!src && !isStaticBackgroundColor}
								idx={data.templateIdx}
								areaIdx={data.areaIdx}
								packageIdx={program?.programIdx}
								isVideoNews
								key={program?.programIdx}
							/>
							<UIFlex align="center" justify="center">
								<UILink
									href={`/program/${program?.programIdx}?${getAreaParams(
										'HOME',
										data.areaIdx,
									)}`}
									key={`more-${program?.programIdx}`}
								>
									<SeeAllButton title={program?.itemTitle} tabIndex={-1} />
								</UILink>
							</UIFlex>
						</UIFlex>
					</UIFlex>
				</UIContainer>
			</Section>
		)
	)
}
export default VideoNewsSection
